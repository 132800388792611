import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    adUpdate : 'PROMOTION_UPDATE',
    adDelete : 'PROMOTION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        promotion: [],
        yt_videos: [],
        promotionLoading: false,
        promotionError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_PROMOTION (state, promotion) {
            state.promotion = promotion;
        },

        PROMOTION_LOADING (state, loading) {
            state.promotionLoading = loading;
        },

        PROMOTION_ERROR(state, error) {
            state.promotionError = error;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updatePromotion({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.adUpdate,
                async () => {
                    let payloadData = { 
                        title: payload.title,
                        start_date: payload.start_date,
                        image: payload.image,
                        end_date: payload.end_date,
                        premium_profile_discount: payload.premium_profile_discount,
                        match_payment_discount: payload.match_payment_discount
                    }
                    let magic = encode(payloadData)
                    response = await axios.patch(V1API.edit_promotion + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })
                    dispatch('fetchPromotion');
                }
            )
            return response
        },

        async deletePromotion({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.adDelete,
                async () => {
                    response = await axios.delete(V1API.delete_promotion + payload).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchPromotion');
                }
            )
            return response
        },

        async fetchPromotion ({ commit }) {
            commit('PROMOTION_LOADING', true);
            commit('PROMOTION_ERROR', false);
            try{

                let result = await axios.get(V1API.get_promotion_list);
                let response = decode(result.data.data)
                commit('SET_PROMOTION', response); 

            }catch(error) {
                commit('PROMOTION_ERROR', true);
            }
            commit('PROMOTION_LOADING', false);
        },
        
        async addPromotion({commit,dispatch}, payload) { 
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_promotion, magic.encodedPayload, magic.config).then(result=>{
                    let data = decode(result.data)
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchPromotion')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updatePromotionStatus({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.get(V1API.toggle_promotion_status + payload).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchPromotion')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }
    },

    getters: {}
}