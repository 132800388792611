import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    faqUpdate : 'FAQ_UPDATE',
    faqDelete : 'FAQ_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        faqs: [],
        details: {},
        faqLoading: false,
        faqError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_FAQ (state, admins) {
            state.faqs = admins;
        },

        SET_FAQ_DETAILS (state, admin) {
            state.details = admin;
        },

        FAQ_LOADING (state, loading) {
            state.faqLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        FAQ_ERROR(state, error) {
            state.faqError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateFaq({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.faqUpdate,
                async () => {
                    let payloadData={ 
                        question: payload.question,
                        answer: payload.answer,
                        slug: payload.slug
                    }
                    let magic=encode(payloadData)
                    response = await axios.patch(V1API.update_faq+payload.slug,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data) 
                        return data
                    })

                    dispatch('fetchFaqList');
                }
            )
            return response
        },

        async deleteFaq({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.faqDelete,
                async () => {
                    response = await axios.delete(V1API.delete_faq+payload).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchFaqList');
                }
            )

            return response
        },

        async fetchFaqList ({ commit }) {
            commit('FAQ_LOADING', true);
            commit('FAQ_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_faqs);
                let response=decode(result.data.data)
                commit('SET_FAQ', response); 

            }catch(error) {
                commit('FAQ_ERROR', true);
            }
            commit('FAQ_LOADING', false);
        },
        
        async addFaq({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_faq,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchFaqList')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }
    },



    getters: {}
}