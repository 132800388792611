import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    userUpdate : 'USER_UPDATE',
    userDelete : 'USER_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        users: [],
        billing_list: [],
        details: {},
        report: {},
        userLoading: false,
        userError: false,
        billingLoading: false,
        billingError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_MONTHLY_REPORT (state, report) {
            state.report = report;
        },

        SET_USERS (state, users) {
            state.users = users;
        },

        USER_LOADING (state, loading) {
            state.userLoading = loading;
        },

        USER_ERROR(state, error) {
            state.submitError = error;
        },

        SET_BILLINGS (state, billing) {
            state.billing_list = billing;
        },

        BILLING_LOADING (state, loading) { 
            state.billingLoading = loading;
        },

        BILLING_ERROR(state, error) {
            state.billingError = error;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async fetchMonthlyReport ({ commit }, payload) {
            let result = await axios.get(V1API.get_monthly_report + payload.start_date + '&end_date=' + payload.end_date); 
            let response=decode(result.data.data)
            commit('SET_MONTHLY_REPORT', response); 
            return result
        },

        async fetchBillingList ({ commit }) {
            commit('BILLING_LOADING', true);
            commit('BILLING_ERROR', false);
            try{
                let result = await axios.get(V1API.get_billing_list); 
                let response=decode(result.data.data)
                commit('SET_BILLINGS', response); 

            }catch(error) {
                commit('BILLING_ERROR', true);
            }
            commit('BILLING_LOADING', false);
        },

        async fetchBillingListBytype ({ commit }, payload) {
            commit('BILLING_LOADING', true);
            commit('BILLING_ERROR', false);
            try{
                let result = await axios.get(V1API.get_billing_list_by_type + payload); 
                let response=decode(result.data.data)
                commit('SET_BILLINGS', response); 

            }catch(error) {
                commit('BILLING_ERROR', true);
            }
            commit('BILLING_LOADING', false);
        },

        async fetchBillingListByGender ({ commit }, payload) {
            commit('BILLING_LOADING', true);
            commit('BILLING_ERROR', false);
            try{
                let result = await axios.get(V1API.get_billing_list_by_gender + payload); 
                let response=decode(result.data.data)
                commit('SET_BILLINGS', response); 

            }catch(error) {
                commit('BILLING_ERROR', true);
            }
            commit('BILLING_LOADING', false);
        },

        async fetchBillingListByBothDate ({ commit }, payload) {
            commit('BILLING_LOADING', true);
            commit('BILLING_ERROR', false);
            try{
                let result = await axios.get(V1API.get_billing_list_by_date + 'start_date=' + payload.start_date + '&end_date=' + payload.end_date); 
                let response=decode(result.data.data)
                commit('SET_BILLINGS', response); 

            }catch(error) {
                commit('BILLING_ERROR', true);
            }
            commit('BILLING_LOADING', false);
        },

        async fetchBillingListByStartDate ({ commit }, payload) {
            commit('BILLING_LOADING', true);
            commit('BILLING_ERROR', false);
            try{
                let result = await axios.get(V1API.get_billing_list_by_date + 'start_date=' + payload); 
                let response=decode(result.data.data)
                commit('SET_BILLINGS', response); 

            }catch(error) {
                commit('BILLING_ERROR', true);
            }
            commit('BILLING_LOADING', false);
        },

        async fetchBillingListByEndDate ({ commit }, payload) {
            commit('BILLING_LOADING', true);
            commit('BILLING_ERROR', false);
            try{
                let result = await axios.get(V1API.get_billing_list_by_date + 'end_date=' + payload); 
                let response=decode(result.data.data)
                commit('SET_BILLINGS', response); 

            }catch(error) {
                commit('BILLING_ERROR', true);
            }
            commit('BILLING_LOADING', false);
        },

        async fetchHeartExchanges ({ commit }) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_heart_exchanges); 
                let response=decode(result.data.data)
                commit('SET_USERS', response); 

            }catch(error) {
                commit('USER_ERROR', true);
            }
            commit('USER_LOADING', false);
        },

        async fetchMatches ({ commit }) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_matches);
                let response=decode(result.data.data)
                commit('SET_USERS', response); 

            }catch(error) {
                commit('USER_ERROR', true);
            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByPremiumStatus ({ commit },payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_premium_status + payload);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {
                commit('USER_ERROR', true);
            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByGender ({ commit },payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_gender + payload);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {
                commit('USER_ERROR', true);
            }
            commit('USER_LOADING', false);
        },

        async updateAccountStatus({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let response = await axios.get(V1API.update_account_status + payload.id + '/' + payload.status).then(result=>{
                    let data=result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch('fetchUsers')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }
    },

    getters: {}
}