import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    degreeUpdate : 'DEGREE_UPDATE',
    degreeDelete : 'DEGREE_DELETE'
};

const mutations = {}; 
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        degree: [],
        degreeLoading: false,
        degreeError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_DEGREE (state, degree) {
            state.degree = degree;
        },

        DEGREE_LOADING (state, loading) {
            state.degreeLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        DEGREE_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateDegree({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.countryUpdate,
                async () => {
                    let payloadData={
                        name: payload.name,
                    }
                    let magic=encode(payloadData)
                    response = await axios.post(V1API.update_degree+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchDegree');
                }
            )
            return response
        },

        async deleteDegree({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.countryDelete,
                async () => {
                    response = await axios.delete(V1API.delete_degree + payload).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchDegree');
                }
            )
            return response
        },

        async fetchDegree ({ commit },payload) {
            commit('DEGREE_LOADING', true);
            commit('DEGREE_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_degree);
                let response=decode(result.data.data)
                commit('SET_DEGREE', response); 

            }catch(error) {
                commit('DEGREE_ERROR', true);
            }
            commit('DEGREE_LOADING', false);
        },

        async createDegree({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_degree,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchDegree')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    
    getters: {}
}