import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    adUpdate : 'AD_UPDATE',
    adDelete : 'AD_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        settings: {},
        adLoading: false,
        adError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        AD_LOADING (state, loading) {
            state.adLoading = loading;
        },

        SET_SETTINGS (state, setting) {
            state.settings = setting;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        AD_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async addAdditionalSettings({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_additional_settings, magic.encodedPayload, magic.config).then(result=>{
                    let data = decode(result.data)
                    return data
                });
                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchAdditionalSettings')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchAdditionalSettings ({ commit }) {
            commit('AD_LOADING', true);
            commit('AD_ERROR', false);
            try{
                let result = await axios.get(V1API.get_additional_settings);
                let response = decode(result.data.data)
                commit('SET_SETTINGS', response); 
            }catch(error) {
                commit('AD_ERROR', true);
            }
            commit('AD_LOADING', false);
        }
    },

    getters: {}
}