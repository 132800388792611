import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    districtUpdate : 'DISTRICT_UPDATE',
    districtDelete : 'DISTRICT_DELETE'
};

const mutations = {}; 
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        district: [],
        districtLoading: false,
        districtError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,

        SET_DISTRICT (state, district) {
            state.district = district;
        },

        DISTRICT_LOADING (state, loading) {
            state.districtLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        DISTRICT_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateDistrict({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.countryUpdate,
                async () => {
                    let payloadData={
                        id: payload.id,
                        name: payload.name,
                        country: payload.country
                    }
                    let magic=encode(payloadData)
                    response = await axios.post(V1API.update_district+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchDistrict');
                }
            )
            return response
        },

        async deleteDistrict({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.countryDelete,
                async () => {
                    response = await axios.delete(V1API.delete_district+'/'+payload).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchDistrict');
                }
            )
            return response
        },

        async fetchDistrict ({ commit },payload) {
            commit('DISTRICT_LOADING', true);
            commit('DISTRICT_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_district);
                let response=decode(result.data.data)
                commit('SET_DISTRICT', response); 

            }catch(error) {
                commit('DISTRICT_ERROR', true);
            }
            commit('DISTRICT_LOADING', false);
        },

        async createDistrict({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_district,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchDistrict')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, message: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }
    },
    
    getters: {}
}