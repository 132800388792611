import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDf4qX4pdYr3aP7_V1ZxjqvC5uQ50G45WE",
    authDomain: "biyerjuti-8246f.firebaseapp.com",
    projectId: "biyerjuti-8246f",
    storageBucket: "biyerjuti-8246f.firebasestorage.app",
    messagingSenderId: "1074554363426",
    appId: "1:1074554363426:web:0d593089ae153340e7747b",
    measurementId: "G-Z6XBL67FHG"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(firebaseApp);

export const requestNotificationPermission = async () => {
  try {
    await Notification.requestPermission();
    const token = await getToken(messaging, { vapidKey: "BGnyDpu9sfyb4i6q3mdn6R7XJiaPTkLgcRVma1W1hSUxOfk16Vytl1gxnAV966O9wDxE-0byLTYyMDIynZ-rgjQ" });
    console.log("Firebase Notification Token:", token);
    return token;
  } catch (error) {
    console.error("Error requesting notification permission:", error);
  }
};

// Listen for messages when the app is in the foreground
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export default firebaseApp;
