import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    positionUpdate : 'POSITION_UPDATE',
    positionDelete : 'POSITION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        position: [],
        positionLoading: false,
        positionError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_POSITION (state, position) {
            state.position = position;
        },

        POSITION_LOADING (state, loading) {
            state.positionLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        POSITION_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updatePosition({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.countryUpdate,
                async () => {
                    let payloadData={ 
                        name: payload.name
                    }
                    let magic=encode(payloadData)
                    response = await axios.post(V1API.update_position+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchPosition');
                }
            )
            return response
        },

        async deletePosition({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.countryDelete,
                async () => {

                    response = await axios.delete(V1API.delete_position + payload).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchPosition');
                }
            )

            return response
        },

        async fetchPosition ({ commit },payload) {
            commit('POSITION_LOADING', true);
            commit('POSITION_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_position);
                let response=decode(result.data.data)
                commit('SET_POSITION', response); 

            }catch(error) {
                commit('POSITION_ERROR', true);
            }
            commit('POSITION_LOADING', false);
        },

        async createPosition({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_position,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchPosition')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}