export default [
    {
        path: '/admin/settings/youtube-videos',
        name:'videos',
        component:()=> import('@/views/pages/cloudx/settings/Videos.vue')
    },
    {
        path: '/admin/settings/additional-settings',
        name:'additional-settings',
        component:()=> import('@/views/pages/cloudx/settings/AdditionalSettings.vue')
    },
    {
        path: '/admin/settings/group-permission',
        name:'group-permission',
        component:()=> import('@/views/pages/cloudx/settings/GroupPermission.vue')
    },
    {
        path: '/admin/settings/group-permission/:id',
        component:()=> import('@/views/pages/cloudx/settings/PermissionsByGroup.vue')
    }
]