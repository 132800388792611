import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    areaUpdate : 'AREA_UPDATE',
    areaDelete : 'AREA_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        area: [],
        areaLoading: false,
        areaError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_AREA (state, area) {
            state.area = area;
        },

        AREA_LOADING (state, loading) {
            state.areaLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        AREA_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateArea({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.countryUpdate,
                async () => {
                    let payloadData={ 
                        id: payload.id,
                        name: payload.name,
                        country: payload.country,
                        district: payload.district
                    }
                    let magic=encode(payloadData)
                    response = await axios.post(V1API.update_area+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data) 
                        return data
                    })

                    dispatch('fetchArea');
                }
            )
            return response
        },

        async deleteArea({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.countryDelete,
                async () => {
                    response = await axios.delete(V1API.delete_area+'/'+payload).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchArea');
                }
            )

            return response
        },

        async fetchArea ({ commit },payload) {
            commit('AREA_LOADING', true);
            commit('AREA_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_area);
                let response=decode(result.data.data)
                commit('SET_AREA', response); 

            }catch(error) {
                commit('AREA_ERROR', true);
            }
            commit('AREA_LOADING', false);
        },

        async createArea({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_area,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchArea')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, message: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }
    },
    getters: {}
}