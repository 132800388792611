// var host = "http://biyerjutiapi.bitflex.xyz";
var host = "https://api-biyeruti.smicee.com"; 
var hostCB="https://ucw.bitflex.xyz";
// var host = "https://asthaapi.bitflex.xyz";

module.exports = {
    V1API : {
        'getAccessToken': host+'/api/authentication/token/admin',
        'refreshAccessToken':host+'/api/authentication/token/refresh',
        'verifyLogin':host+'/api/authentication/token/verify',

        'get_summary':host+'/api/profile/statistics/count',

        'fetch_admin_list':host+'/api/authentication/admin/list',
        'create_new_admin':host+'/api/authentication/register/admin',
        'update_admin':host+'/api/authentication/admin/edit',
        'delete_admin':host+'/api/authentication/delete',
        'fetch_activity_log_by_admin':host+'/api/report/activity-admin/', 

        'fetch_activity_log_by_user':host+'/api/report/activity/',  
        'fetch_summary_by_user':host+'/api/report/summery/',  

        'fetch_users':host+'/api/profile/optimized/list',
        'fetch_user_details':host+'/api/profile/details/admin-verify/', 
        'fetch_users_by_status':host+'/api/profile/optimized/list/by-profile-status/',
        'fetch_users_by_premium_status':host+'/api/profile/optimized/list/by-premium-status/',
        'fetch_users_by_status_premium_status':host+'/api/profile/optimized/list/by-profile-status/',
        'fetch_users_by_gender':host+'/api/profile/optimized/list/by/gender/',
        'fetch_users_by_device':host+'/api/profile/optimized/list/by/device-type/',
        'update_account_status':host+'/api/profile/status/',
        'get_further_review_list':host+'/api/profile/list/profile-status/further-review',
        'add_feedback':host+'/api/feedback/admin/edit/',
        'update_premium_status':host+'/api/profile/premium-status/',  
        'update_personal_informations':host+'/api/profile/personal-information/edit/',  
        'update_educational_informations':host+'/api/profile/educational-information/edit/',  
        'update_professional_informations':host+'/api/profile/professional-information/edit/',  
        'update_family_informations':host+'/api/profile/family-information/edit/',  
        'update_present_address':host+'/api/profile/present-address/edit/',  
        'update_permanent_address':host+'/api/profile/permanent-address/edit/',  
        'delete_profile_picture':host+'/api/profile/picture-information/delete/',  
        'activate_all_checked_user':host+'/api/profile/update/profile-status/activate/all',  
        'activate_selected_user':host+'/api/profile/update/profile-status/activate/selected-profiles',  
        'add_all_feedbacks':host+'/api/feedback/admin/create/',  
        'get_all_feedbacks':host+'/api/feedback/admin/view/',  
        'toggle_feedback_resolved':host+'/api/feedback/admin/resolved/toggle/',   
        'fetch_saved_parameters':host+'/api/filter/viewers/saved/parameter/',   

        // Promotion
        'create_promotion':host+'/api/promotion/create',  
        'get_promotion_list':host+'/api/promotion/',  
        'toggle_promotion_status':host+'/api/promotion/active-toggle/',  
        'edit_promotion':host+'/api/promotion/update/',  
        'delete_promotion':host+'/api/promotion/delete/',

        // Advertisement
        'create_ad':host+'/api/ad/create',  
        'get_ad_list':host+'/api/ad/',  
        'toggle_ad_status':host+'/api/ad/active/toggle/',  
        'edit_ad':host+'/api/ad/update/',  
        'delete_ad':host+'/api/ad/delete/',  

        // YouTube Videos
        'create_yt_video':host+'/api/settings/create',  
        'get_yt_video_list':host+'/api/settings/',  
        'delete_yt_video':host+'/api/settings/delete/', 
        
        // Additional Settings
        'create_additional_settings':host+'/api/settings/site/update',  
        'get_additional_settings':host+'/api/settings/site/view',  

        // Billing
        'get_billing_list':host+'/api/billing/list-admin/view',  
        'get_billing_list_by_type':host+'/api/billing/list-admin/view?status=',  
        'get_billing_list_by_gender':host+'/api/billing/list-admin/view?gender=',  
        'get_billing_list_by_date':host+'/api/billing/list-admin/view?',  
        'get_monthly_report':host+'/api/billing/monthly-total?start_date=',  

        // Activity
        'high_activity_user':host+'/api/report/activity/high-activity-users/',
        'low_activity_user':host+'/api/report/activity/low-activity-users/',
        'no_activity_user':host+'/api/report/activity/no-activity-users/',
        'online_user':host+'/api/profile/optimized/list/online',

        // Heart & Match
        'fetch_heart_exchanges':host+'/api/heart/exchanged/list', 
        'fetch_matches':host+'/api/heart/matched/list', 

        // Contact Requests
        'get_contact_request':host+'/api/contact-us/message/list',
        'delete_contact_request':host+'/api/contact-us/delete',

        // Group & Permission
        'create_user_group':host+'/api/permission/user-groups/create',
        'fetch_user_group':host+'/api/permission/user-groups',
        'delete_group':host+'/api/permission/user-groups/delete/',
        'assign_user_to_group':host+'/api/permission/user-groups/assign',
        'fetch_group_details':host+'/api/permission/user-groups/details/',
        'fetch_permissions':host+'/api/permission/user-permissions',
        'assign_user_permission':host+'/api/permission/user-permissions/toggle',
        'permissions_by_group':host+'/api/permission/user-permissions/by-role/',
        'delete_assigned_user':host+'/api/permission/user-group/delete-user',

        'password_reset_request':host+'/api/password_reset/',
        'validate_reset_token':host+'/api/password_reset/validate_token/',
        'reset_password':host+'/api/password_reset/confirm/',

        'fetch_hobby': host+"/api/profile/hobby/list",  
        'create_hobby': host+"/api/profile/hobby/create", 
        'update_hobby': host+"/api/profile/hobby/edit",
        'delete_hobby': host+"/api/profile/hobby/delete",

        'fetch_country': host+"/api/location/country",  
        'create_country': host+"/api/location/country/create", 
        'update_country': host+"/api/location/country/edit",
        'delete_country': host+"/api/location/country/delete",

        'fetch_faqs': host+"/api/information/faq/list",  
        'create_faq': host+"/api/information/faq/create", 
        'update_faq': host+"/api/information/faq/update/",
        'delete_faq': host+"/api/information/faq/delete/",

        'fetch_district': host+"/api/location/district", 
        'create_district': host+"/api/location/district/create", 
        'update_district': host+"/api/location/district/edit",
        'delete_district': host+"/api/location/district/delete",

        'fetch_area': host+"/api/location/area", 
        'create_area': host+"/api/location/area/create", 
        'update_area': host+"/api/location/area/edit",
        'delete_area': host+"/api/location/area/delete",

        'fetch_profession': host+"/api/profile/profession/industry-category/list",  // new industry category
        'create_profession': host+"/api/profile/profession/industry-category/create", 
        'update_profession': host+"/api/profile/profession/industry-category/edit",
        'delete_profession': host+"/api/profile/profession/industry-category/delete/",

        'fetch_position': host+"/api/profile/profession/designation/list",  // new designation
        'create_position': host+"/api/profile/profession/designation/create", 
        'update_position': host+"/api/profile/profession/designation/edit",
        'delete_position': host+"/api/profile/profession/designation/delete/", 

        'fetch_organization': host+"/api/profile/profession/organization/list",  
        'create_organization': host+"/api/profile/profession/organization/create", 
        'update_organization': host+"/api/profile/profession/organization/edit",
        'delete_organization': host+"/api/profile/profession/organization/delete/",

        'fetch_degree': host+"/api/profile/education/degree/list", 
        'create_degree': host+"/api/profile/education/degree/create", 
        'update_degree': host+"/api/profile/education/degree/edit",
        'delete_degree': host+"/api/profile/education/degree/delete/", 

        'fetch_subject': host+"/api/profile/education/subject/list", 
        'create_subject': host+"/api/profile/education/subject/create", 
        'update_subject': host+"/api/profile/education/subject/edit",
        'delete_subject': host+"/api/profile/education/subject/delete/",

        'fetch_institution': host+"/api/profile/education/institutions/list", 
        'create_institution': host+"/api/profile/education/institutions/create", 
        'update_institution': host+"/api/profile/education/institutions/edit",
        'delete_institution': host+"/api/profile/education/institutions/delete/", 

        // .......

        'user_info_get': host +'/api/seller/account',
        'user_info_update': host +'/api/user/info/update',
        'user_pass_update': host +'/api/change-password',

        'category': hostCB+"/portfolio/categories",
        'category_by_concern': hostCB+"/portfolio/categories/by_concern/",

        'project_add':hostCB+"/portfolio/projects/create",
        'project_get':hostCB+"/portfolio/projects",
        'project_get_by_concern':hostCB+"/portfolio/projects",
        'project_update':hostCB+"/portfolio/projects/",
        'project_by_id':hostCB+"/portfolio/projects/",
        'project_glance_toggle':hostCB+"/portfolio/glance/toggle/",
        'project_at_a_glance_toggle':hostCB+"/portfolio/at_glance/toggle/",

        'campaign_add':hostCB+"/portfolio/campaigns/create",
        'campaign_get':hostCB+"/portfolio/campaigns",
        'campaign_get_by_concern':hostCB+"/portfolio/campaigns",
        'campaign_update':hostCB+"/portfolio/campaigns/",
        'campaign_by_id':hostCB+"/portfolio/campaigns/",

        'weight_updater': hostCB+'/portfolio/weights/',
        'gallery_add':hostCB+"/portfolio/gallery/create",
        'gallery_get':hostCB+"/portfolio/gallery",
        'gallery_get_by_concern':hostCB+"/portfolio/gallery",
        'gallery_update':hostCB+"/portfolio/gallery/",
        'gallery_by_id':hostCB+"/portfolio/gallery/",

        'media_by_id':hostCB+"/portfolio/media/project/",
        'media_add':hostCB+"/portfolio/media",


        'contact_get':hostCB+"/contact/contact",
        'contact_delete':hostCB+"/contact/contact/delete/",

        'job_get':hostCB+"/contact/jobs",
        'job_add':hostCB+"/contact/jobs/create",
        'job_edit':hostCB+"/contact/jobs/edit/",
        'job_delete':hostCB+"/contact/jobs/delete/",
        'job_status':hostCB+"/contact/jobs/toggle/",


        'application':hostCB+"/contact/job/applications",
        'booking':hostCB+"/portfolio/bookings/list/all",
        'booking_status':hostCB+"/portfolio/bookings/toggle/",
    }
};
