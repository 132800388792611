import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import store from '@/store/index'
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/cloudX/dashboard'
import administration from './routes/cloudX/administration'
import subscribers from './routes/cloudX/subscribers'
import heartManagement from './routes/cloudX/heart-management'
import databaseManagement from './routes/cloudX/database-management'
import behaviour from './routes/cloudX/behaviour'
import ad from './routes/cloudX/ad'
import promotion from './routes/cloudX/promotion'
import bjsettings from './routes/cloudX/settings'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'


import legalities from './routes/cloudX/legalities'
import {can} from "@core/libs/acl/utils";
import forgotPassword from "@/router/routes/cloudX/forgotPassword";



Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {path: '/', redirect: {name: 'dashboard'}},
        ...apps,
        ...dashboard,
        ...administration,
        ...subscribers,
        ...heartManagement,
        ...databaseManagement,
        ...behaviour,
        ...bjsettings,
        ...ad,
        ...promotion,
        ...pages,
        ...chartsMaps,
        ...formsTable,
        ...uiElements,

        ...others,
        ...legalities,
        ...forgotPassword,

        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, _, next) => {
    (to.name !== 'auth-login' && to.name!=='forgot-password' && to.name!=='reset-password')? store.dispatch('auth/isLoggedIn') : ''
    const isLoggedIn = store.state.auth.isUserLoggedIn()
    let permissions = JSON.parse(localStorage.getItem('permissions'))
    let canGo = false;

    if (to.meta.codename != undefined) {
        canGo = permissions.includes(to.meta.codename)
    } else {
        canGo = true
    }


    if (to.name === 'auth-login'||to.name === 'forgot-password'||to.name === 'reset-password') {
        if (isLoggedIn) {
            return next({name: 'dashboard'})
        } else {
            next()
        }
    } else {
        if (isLoggedIn) {
            if (canGo) {
                return next()
            } else {
                return next({name: 'misc-not-authorized'})
            }
        } else {
            return next({name: 'auth-login'})
        }
    }


    // if(!(to.name=='auth-login')){
    //   if(isLoggedIn){
    //     return next()
    //   }else{
    //     return next({ name: 'auth-login' })
    //   }
    // }else{
    //   return next()
    // }
    // if (!canNavigate(to)) {
    //   // Redirect to login if not logged in
    //   if (!isLoggedIn)
    
    //   // If logged in => not authorized
    //   return next({ name: 'misc-not-authorized' })
    // }

    // Redirect if logged in
    // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    //   const userData = getUserData()
    //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    // }


})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
