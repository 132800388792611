import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    adUpdate : 'AD_UPDATE',
    adDelete : 'AD_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        ad: [],
        yt_videos: [],
        adLoading: false,
        adError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },

    mutations : {
        ...mutations,
        SET_AD (state, ad) {
            state.ad = ad;
        },

        AD_LOADING (state, loading) {
            state.adLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        AD_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SET_YT_VIDEOS (state, videos) {
            state.yt_videos = videos;
        }
    },

    actions: {
        async updateAdvertisement({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.adUpdate,
                async () => {
                    let payloadData={ 
                        title: payload.title,
                        image: payload.image,
                        link: payload.link,
                        runtime: payload.runtime
                    }
                    let magic=encode(payloadData)
                    response = await axios.patch(V1API.edit_ad +payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })
                    dispatch('fetchAdvertisement');
                }
            )
            return response
        },

        async deleteAdvertisement({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.adDelete,
                async () => {
                    response = await axios.delete(V1API.delete_ad + payload).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchAdvertisement');
                }
            )

            return response
        },

        async fetchAdvertisement ({ commit }) {
            commit('AD_LOADING', true);
            commit('AD_ERROR', false);
            try{
                let result = await axios.get(V1API.get_ad_list);
                let response=decode(result.data.data)
                commit('SET_AD', response); 

            }catch(error) {
                commit('AD_ERROR', true);
            }
            commit('AD_LOADING', false);
        },
        
        async addAdvertisement({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_ad,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchAdvertisement')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateAdvertisementStatus({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.get(V1API.toggle_ad_status + payload).then(result=>{
                    let data=result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchAdvertisement')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        // YouTube Videos
        async addYTVideos({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_yt_video, magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    return data
                });
                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchYTVideos')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchYTVideos ({ commit }) {
            commit('AD_LOADING', true);
            commit('AD_ERROR', false);
            try{
                let result = await axios.get(V1API.get_yt_video_list);
                let response = decode(result.data.data)
                commit('SET_YT_VIDEOS', response); 
            }catch(error) {
                commit('AD_ERROR', true);
            }
            commit('AD_LOADING', false);
        },

        async deleteYTVideo({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.adDelete,
                async () => {
                    response = await axios.delete(V1API.delete_yt_video + payload).then(result=>{
                        let data = decode(result.data)
                        return data
                    });
                    dispatch('fetchYTVideos');
                }
            )
            return response
        }
    },

    getters: {}
}