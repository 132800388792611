import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    adUpdate : 'AD_UPDATE',
    adDelete : 'AD_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        group: [],
        group_detail: {},
        permission_by_group: {},
        groupLoading: false,
        groupError: false,

        permissions: [],
        permissionLoading: false,
        permissionError: false,

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        GROUP_LOADING (state, loading) {
            state.groupLoading = loading;
        },

        GROUP_ERROR(state, error) {
            state.groupError = error;
        },

        SET_USER_GROUP (state, group) {
            state.group = group;
        },

        PERMISSION_LOADING (state, loading) {
            state.permissionLoading = loading;
        },

        PERMISSION_ERROR(state, error) {
            state.permissionError = error;
        },

        SET_PERMISSIONS (state, permissions) {
            state.permissions = permissions;
        },

        SET_GROUP_DETAILS (state, detail) {
            state.group_detail = detail;
        },

        SET_PERMISSION_BY_GROUP (state, permission) {
            state.permission_by_group = permission;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async addUserGroup({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_user_group, magic.encodedPayload, magic.config).then(result=>{
                    let data = decode(result.data)
                    return data
                });
                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchUserGroup')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchUserGroup ({ commit }) {
            commit('GROUP_LOADING', true);
            commit('GROUP_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_user_group);
                let response = decode(result.data.data)
                commit('SET_USER_GROUP', response); 
            }catch(error) {
                commit('GROUP_ERROR', true);
            }
            commit('GROUP_LOADING', false);
        },

        async fetchPermissions ({ commit }) {
            commit('PERMISSION_LOADING', true);
            commit('PERMISSION_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_permissions);
                let response = decode(result.data.data)
                commit('SET_PERMISSIONS', response); 
            }catch(error) {
                commit('PERMISSION_ERROR', true);
            }
            commit('PERMISSION_LOADING', false);
        },

        async assignGroupPermission({commit, dispatch}, payload) { 
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.assign_user_permission,{
                        group_name: magic.encodedPayload.group_name,
                        codename: magic.encodedPayload.codename
                    }, magic.config).then(result=>{
                    let data = decode(result.data)
                    return data
                });
                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchGroupDetails', payload.id)
                dispatch ('fetchPermissionsByGroup', payload.id)
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async assignUsertoGroup({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.assign_user_to_group, magic.encodedPayload, magic.config).then(result=>{
                    let data = decode(result.data)
                    return data
                });
                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchUserGroup')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
                return { error: true, response: e.response.data.response };
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchGroupDetails ({ commit }, payload) {
            commit('GROUP_LOADING', true);
            commit('GROUP_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_group_details + payload);
                let response = decode(result.data.data)
                commit('SET_GROUP_DETAILS', response); 
            }catch(error) {
                commit('GROUP_ERROR', true);
            }
            commit('GROUP_LOADING', false);
        },

        async fetchPermissionsByGroup ({ commit }, payload) {
            commit('GROUP_LOADING', true);
            commit('GROUP_ERROR', false);
            try{
                let result = await axios.get(V1API.permissions_by_group + payload);
                let response = decode(result.data.data)
                commit('SET_PERMISSION_BY_GROUP', response); 
            }catch(error) {
                commit('GROUP_ERROR', true);
            }
            commit('GROUP_LOADING', false);
        },

        async deleteGroup({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.adDelete,
                async () => {
                    response = await axios.delete(V1API.delete_group + payload).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchUserGroup');
                }
            )
            return response
        },

        async deleteAssignedAdmin({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.adDelete,
                async () => {
                    response = await axios.post(V1API.delete_assigned_user, {
                        profile_id: payload.profile_id,
                        group_name: payload.group_name
                    }).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchUserGroup');
                }
            )
            return response
        }
    },

    getters: {}
}